:global {
.ic-tokeninput {
  display: inline-block;
}

.ic-tokeninput-list {
  display: none;
  position: absolute;
  z-index: 1;
  border: 1px solid #aaa;
  background: #fff;
  top: 100%;
  left: 0;
  padding: 5px 0px;
  max-height: 400px;
  overflow: auto;
  font-size: 12px;
  font-family: sans-serif;
  width: 100%;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.ic-tokeninput-is-open .ic-tokeninput-list {
  display: block;
}

.ic-tokeninput-option:focus {
  outline: 0;
  color: white;
  background: hsl(200, 50%, 50%);
}

.ic-tokeninput-input {
  padding-right: 20px;
  width: 100%;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border: 0 none;
  outline: 0;
  margin-top: 6px;
  margin-bottom: 6px;
  background-color: transparent;
}

.ic-token-label {
  line-height: 20px;
}

.ic-tokeninput-button {
  display: inline-block;
  position: absolute;
  cursor: default;
  outline: none;
  top: 2px;
  right: 6px;
  font-size: 14px;
  cursor: default;
}

.ic-tokeninput-button:active {
  color: #4095BF;
}

.ic-tokeninput-option {
  display: block;
  padding: 2px 16px;
  cursor: default;
}

.ic-tokeninput-selected:before {
  content: '✓';
  position: absolute;
  left: 4px;
}

.flex {
  /* 2009 */
  display: -webkit-box;
  display: -moz-box;
  /*display: box;*/

  /* 2011 */
  display: -moz-flexbox;
  display: -ms-flexbox;
  display: -o-flexbox;
  /*display: flexbox;*/

  /* 2012 */
  display: -webkit-flex;
  display: flex;
}

.inline-flex {
  /* 2009 */
  display: -webkit-inline-box;
  display: -moz-inline-box;

  /* 2011 */
  display: -moz-inline-flexbox;
  display: -ms-inline-flexbox;
  display: -o-inline-flexbox;

  /* 2012 */
  display: -webkit-inline-flex;
  display: inline-flex;
}

.ic-tokens {
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  list-style: none;
  padding: 6px 6px;
  border: 1px solid #A6A6A6;
  background-color: white;
  border-radius: 3px;
  cursor: text;
  position: relative;
  will-change: transform;
  flex-wrap: wrap;
  min-height: 41px;
}

.ic-tokens-disabled {
  border: 1px solid #CCCCCC;
  background-color: #eee;
  color: #eee;
  cursor: default;
  -moz-box-shadow:    inset 0 0 6px #ddd;
  -webkit-box-shadow: inset 0 0 6px #ddd;
  box-shadow:         inset 0 0 6px #ddd;
}

.ic-token {
  background-color: #F5F7F8;
  border: 1px solid #C1C7CF;
  color: #7C8E9A;
  border-radius: 3px;
  padding: 3px 8px;
  margin-right: 6px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.ic-token-delete-button {
  font-size: 1.2rem;
  margin: 8px 4px 0 6px;
  line-height: 0.5rem;
  cursor: pointer;
}

.ic-token-delete-button:hover {
  color: #C00;
}

.ic-tokeninput {
  /* 2009 */
  display: -webkit-inline-box;
  display: -moz-inline-box;

  /* 2011 */
  display: -moz-inline-flexbox;
  display: -ms-inline-flexbox;
  display: -o-inline-flexbox;

  /* 2012 */
  display: -webkit-inline-flex;
  display: inline-flex;
}

.ic-tokeninput-button {
  display: none;
}

.ic-tokeninput-loading {
  position: absolute;
  bottom: 9px;
  right: 2px;
  align-items: center;
  padding-right: 6px;
}

}

