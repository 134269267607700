@use "sass:math";

$hover-color: lighten(#0a5de4, 5%);

.attachment {
  display: inline-block;

  &.inSelection,
  &.isSelected,
  &:hover {
    box-shadow: $hover-color 0 0 5px 0;
    :global {
      .panel {
        border: 2px solid $hover-color;
        background: $hover-color;
      }
      .panel-footer {
        background: $hover-color;
        border-top: 1px solid $hover-color;
        color: white;
      }

      .attachment-preview {
        background: lighten($hover-color, 10%);
      }

      .fa {
        color: white;
      }

      .image-wrap {
        opacity: 0.75;
      }
    }
  }

  :global {
    .panel-footer {
      padding: 5px;
    }

    .props {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;

      &.title {
        font-weight: 700;
      }

      // &:hover {
      //   background: #Fc0;
      //   text-overflow: initial;
      //   overflow: visible;
      //   z-index: 9000;
      // }
    }

    .panel {
      margin-bottom: 0 !important;
      border-width: 2px;
    }

    .panel-footer {
      &:before,
      &:after {
        content: "\0020";
        display: block;
        height: 0;
        overflow: hidden;
      }
      &:after {
        clear: both;
      }

      .links {
        display: flex;
        justify-content: end;
      }

      a {
        font-size: 2rem;
        margin-top: 1rem;
        padding: 0.5rem 1rem;
        border-radius: 4px;

        &:hover {
          color: red;
          background-color: rgb(255, 231, 231);

          .fa {
            color: red;
          }
        }
      }
    }
  }

  &.dirty {
    filter: grayscale(60%);
    opacity: 0.5;
    .panel {
      border: 1px solid #eee;
      :global {
        img,
        .preview,
        .icon {
          opacity: 0.7;
        }
      }
    }
  }
}
