.AttachmentsFilterForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;

  :global {
    #file_or_title_de_cont {
      flex-basis: 1;
      width: 100%;
    }

    .form-group {
      flex-basis: 33.3333%;
      padding: 0 2rem;
      white-space: nowrap;
      width: auto;
      margin-bottom: 1rem;

      select {
        width: 100%;
      }
    }

    .w-100 {
      width: 100%;

      > * {
        width: 100% !important;
      }

      input {
        width: 100% !important;
      }
    }
  }
}
